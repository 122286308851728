<template>
  <div>
    <HeaderPanel
      title="Transaction Redeem Point Report"
      @sidebar="$refs.transactionRedeemReport.show()"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportFile()"
    />
    <div class="bg-white p-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
        @sort-changed="sortingChanged"
        :sort-by.sync="filter.sortBy"
        no-local-sorting
        :sort-desc.sync="filter.sortDesc"
      >
        <template v-slot:cell(branch_name)="data">
          <div>{{ data.item.branch_name }} ({{ data.item.plant_id }})</div>
          <!-- <div class="text-center">
              <small>{{ data.item.member_id }}</small>
            </div> -->
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTakeProduct"
        @pagination="paginationProduct"
        :filter="filter"
        :rows="rows"
        :limit="2"
      />
      <SideBarFilter
        id="transactionRedeemReport"
        ref="transactionRedeemReport"
        :filter="filter"
        :placeholder="'Search Name'"
        @clearFilter="clearFilter"
        @searchAll="filterActions"
        :hideStatusFilter="false"
        :hideSearchBar="true"
      >
        <template v-slot:filter-option>
          <div class="mb-3 input-select">
            <InputSelect
              title="Transaction Date"
              name="transaction-date"
              class="mt-2"
              v-model="filter.customFilterRequest.LastDays"
              :options="dateType"
              valueField="value"
              textField="text"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >-- Select Transaction Date --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
          <div v-if="filter.customFilterRequest.LastDays == 0" class="mb-3">
            <InputDatePickerFilter
              textFloat="Transaciton Date From"
              name="transaction-date-from"
              ref="transaction-date-from"
              :max-datetime="
                filter.customFilterRequest.TransactionDateTo ||
                $moment().format()
              "
              :value="filter.customFilterRequest.TransactionDateFrom"
              @input="
                (val) => (filter.customFilterRequest.TransactionDateFrom = val)
              "
            />
          </div>
          <div v-if="filter.customFilterRequest.LastDays == 0" class="mb-3">
            <InputDatePickerFilter
              textFloat="Transaciton Date To"
              name="transaction-date-to"
              ref="transaction-date-to"
              :min-datetime="filter.customFilterRequest.TransactionDateFrom"
              :max-datetime="$moment().format()"
              :value="filter.customFilterRequest.TransactionDateTo"
              @input="
                (val) => (filter.customFilterRequest.TransactionDateTo = val)
              "
            />
          </div>
          <div class="my-3">
            <label class="main-label"> Member tier</label>
            <multiselect
              :close-on-select="false"
              :clear-on-select="false"
              multiple
              v-model="filter.customFilterRequest.MemberTierFilter"
              :options="memberTiers"
              placeholder="Select Member Tier"
              track-by="id"
              label="memberTierName"
            ></multiselect>
          </div>
        </template>
      </SideBarFilter>
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Multiselect from "vue-multiselect";
export default {
  components: { HeaderPanel, Multiselect },
  data() {
    return {
      fields: [
        {
          key: "invoice_no",
          label: "Invoice No.",

          sortable: true,
        },
        // // {
        // //   key: "member_tier",
        // //   label: "Member Tier",

        // //   sortable: true,
        // // },
        {
          key: "branch_name",
          label: "Branch",
          // tdClass: "text-left",
          sortable: true,
        },

        {
          key: "transaction_date",
          label: "Transaction Date",

          sortable: true,
        },
        {
          key: "member_id",
          label: "Member ID.",

          sortable: true,
        },
        {
          key: "telephone",
          label: "Telephone",

          sortable: true,
        },

        {
          key: "member_status",
          label: "Member Status",

          sortable: true,
        },

        {
          key: "used_point",
          label: "Used Point",

          sortable: true,
        },
        {
          key: "discount_value",
          label: "Discount",

          sortable: true,
        },
        {
          key: "transaction_status",
          label: "Transaction Status",

          sortable: true,
        },
      ],
      rows: 0,
      items: [],
      dateType: [
        { text: "Last 7 Days", value: 7 },
        { text: "Last 30 Days", value: 30 },
        { text: "Last 365 Days", value: 365 },
        { text: "Custom", value: 0 },
      ],
      filter: {
        page: 1,
        take: 10,
        customFilterRequest: {
          MemberTierFilter: [],
          TransactionDateFrom: "",
          TransactionDateTo: "",
          LastDays: 30,
        },
        PageNumber: 1,
        RecordPerPage: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
      },
      filterFreeze: {
        page: 1,
        take: 10,
        customFilterRequest: {
          MemberTierFilter: [],
          TransactionDateFrom: "",
          TransactionDateTo: "",
          LastDays: 30,
        },
        PageNumber: 1,
        RecordPerPage: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
      },
      memberTiers: [],
      isBusy: false,
    };
  },
  created() {
    this.getData();
    this.getFilter();
  },
  methods: {
    async getFilter() {
      const res = await this.$report(`/DashBoard/Filter/MemberTier `);
      this.memberTiers = res.data.detail;
    },
    async getData() {
      this.isBusy = true;
      let payload = { ...this.filterFreeze };

      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      const res = await this.$report.post(
        `/DashBoard/transaction_redeem`,
        payload
      );
      this.items = res.data.detail.data;

      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    sortingChanged(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterFreeze.SortType &&
        !ctx.sortDesc &&
        this.filterFreeze.SortColumnId == index
      ) {
        this.filterFreeze.SortColumnId = 0;
        this.filterFreeze.sortBy = "";
        this.filterFreeze.sortDesc = "";
      } else {
        this.filterFreeze.SortColumnId = index;
        this.filterFreeze.SortType = ctx.sortDesc;
      }
      this.getData();
    },
    paginationProduct(val) {
      this.filterFreeze.page = val;
      this.getData();
    },
    handleChangeTakeProduct(val) {
      this.filterFreeze.take = val;
      this.filterFreeze.page = 1;
      this.getData();
    },
    clearFilter() {
      this.filter = {
        page: 1,
        take: 10,
        customFilterRequest: {
          MemberTierFilter: [],
          TransactionDateFrom: "",
          TransactionDateTo: "",
          LastDays: 30,
        },
        PageNumber: 1,
        RecordPerPage: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
      };
      this.filterFreeze = {
        page: 1,
        take: 10,
        customFilterRequest: {
          MemberTierFilter: [],
          TransactionDateFrom: "",
          TransactionDateTo: "",
          LastDays: 30,
        },
        PageNumber: 1,
        RecordPerPage: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
      };
      this.$refs.transactionRedeemReport.hide();
      this.getData();
    },
    filterActions() {
      this.filterFreeze = JSON.parse(JSON.stringify(this.filter));
      for (const [keys, value] of Object.entries(
        this.filter.customFilterRequest
      )) {
        if (typeof value == "object") {
          this.filterFreeze.customFilterRequest[keys] = value.map(
            (el) => el.id
          );
        }
      }
      this.getData();
    },
    handleSearch() {
      this.getData();
    },
    autoSearch() {
      this.getData();
    },
    async exportFile() {
      let payload = { ...this.filterFreeze };

      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      const res = await this.$report.post(
        `/DashBoard/transaction_redeem/export`,
        payload,
        {
          responseType: "blob",
        }
      );
      let data = res;
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `Transaction-Redeem-Point-Report.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style></style>
